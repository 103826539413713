<template>
  <div class="mobile-menu">
    <client-only>
      <div class="mobile-menu-title">
        Каталог
      </div>
      <div class="nav_items_wrapper">
        <ul class="nav_items">
          <div class="nav_back_btn">
            Назад в меню
          </div>

          <li class="nav_item nav_item__wedding">
            <!--<span class="nav_item_link">Свадебные платья</span>
            <div class="nav_dropdown nav_dropdown__alter">
              <div class="nav_dropdown_cols">
                <div class="nav_dropdown_col style">
                  <strong class="nav_dropdown_title">Стили</strong>
                  <ul class="nav_dropdown_list nav_dropdown_list__slider">
                    <li v-for="style in weddingStylesWithPics" :key="style.id">
                      <nuxt-link
                        :to="`/wedding/?style=${style.slug}`"
                        @click.native="menuItemClickHandler(`/wedding/?style=${style.slug}`)"
                      >
                        <strong>{{ style.name }}</strong>
                        <ins
                          :class="[`nav_style__${style.slug === 'korotkie' ? 'korotkie1' : style.slug}`]"
                          class="nav_style"
                        />
                      </nuxt-link>
                    </li>
                    <li class="nav_dropdown_addition">
                      <div class="nav_dropdown_addition_title" data-menu="dop_stili_wedding">
                        Дополнительные стили
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="nav_dropdown_col_level3">
                  <div data-menu-ref="dop_stili_wedding" class="nav_dropdown_col">
                    <ul class="nav_dropdown_list">
                      <li v-for="style in weddingStylesWithoutPics" :key="style.id" class="nav_dropdown_item">
                        <nuxt-link
                          :to="`/wedding/?style=${style.slug}`"
                          @click.native="menuItemClickHandler(`/wedding/?style=${style.slug}`)"
                        >
                          {{ style.name }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </div>
                  <div class="nav_dropdown_col brand">
                    <strong class="nav_dropdown_title">Топ бренды</strong>
                    <ul class="nav_dropdown_list">
                      <nuxt-link
                        v-for="brand in weddingBrands"
                        :key="brand.id"
                        tag="li"
                        :to="`/wedding/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/wedding/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                      <div class="clear" />
                    </ul>
                  </div>
                  <!- -<div class="nav_dropdown_col">
                  <strong class="nav_dropdown_title">Стоимость</strong>
                  <ul class="nav_dropdown_list">
                    <li>
                      <nuxt-link
                        to="/wedding/?price_min=10000&price_max=25000"
                        @click.native="menuItemClickHandler('/wedding/?price_min=10000&price_max=25000')"
                        >10000 - 25000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                    <li>
                      <nuxt-link
                        to="/wedding/?price_min=25000&price_max=50000"
                        @click.native="menuItemClickHandler('/wedding/?price_min=25000&price_max=50000')"
                        >25000 - 50000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                    <li>
                      <nuxt-link
                        to="/wedding/?price_min=50000&price_max=75000"
                        @click.native="menuItemClickHandler('/wedding/?price_min=50000&price_max=75000')"
                        >50000 - 75000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                    <li>
                      <nuxt-link
                        to="/wedding/?price_min=75000&price_max=1000000"
                        @click.native="menuItemClickHandler('/wedding/?price_min=75000&price_max=1000000')"
                        >от 75000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                  </ul>
                </div>- ->
                  <div class="nav_dropdown_col nav_dropdown_col__proceed">
                    <nuxt-link
                      to="/wedding/"
                      @click.native="menuItemClickHandler('/wedding/')"
                    >
                      <i /><span>Смотреть<br>полный каталог</span>
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>-->

            <nuxt-link
              class="nav_item_link"
              to="/wedding/"
              @click.native="menuItemClickHandler('/wedding/')"
            >
              Свадебные платья
            </nuxt-link>
          </li>
          <li class="nav_item nav_item__evening">
            <!--<span class="nav_item_link">Вечерние</span>
            <div class="nav_dropdown nav_dropdown__alter">
              <div class="nav_dropdown_cols">
                <div class="nav_dropdown_col style">
                  <strong class="nav_dropdown_title">Стили</strong>
                  <ul class="nav_dropdown_list nav_dropdown_list__slider">
                    <li v-for="style in eveningStylesWithPics" :key="style.id">
                      <nuxt-link
                        :to="`/evening/?style=${style.slug}`"
                        @click.native="menuItemClickHandler(`/evening/?style=${style.slug}`)"
                      >
                        <strong>{{ style.name }}</strong>
                        <ins :class="[`nav_style__${style.slug}`]" class="nav_style" />
                      </nuxt-link>
                    </li>
                    <li class="nav_dropdown_addition">
                      <div class="nav_dropdown_addition_title -dark" data-menu="dop_stili_evening">
                        Дополнительные стили
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="nav_dropdown_col_level3">
                  <div data-menu-ref="dop_stili_evening" class="nav_dropdown_col">
                    <ul class="nav_dropdown_list">
                      <li v-for="style in eveningStylesWithoutPics" :key="style.id" class="nav_dropdown_item">
                        <nuxt-link
                          :to="`/evening/?style=${style.slug}`"
                          @click.native="menuItemClickHandler(`/evening/?style=${style.slug}`)"
                        >
                          {{ style.name }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </div>
                  <div class="nav_dropdown_col brand">
                    <strong class="nav_dropdown_title">Топ бренды</strong>
                    <ul class="nav_dropdown_list">
                      <nuxt-link
                        v-for="brand in eveningBrands"
                        :key="brand.id"
                        tag="li"
                        :to="`/evening/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/evening/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                      <div class="clear" />
                    </ul>
                  </div>
                  <!- -<div class="nav_dropdown_col">
                  <strong class="nav_dropdown_title">Стоимость</strong>
                  <ul class="nav_dropdown_list">
                    <li>
                      <nuxt-link
                        to="/evening/?price_min=10000&price_max=25000"
                        @click.native="menuItemClickHandler('/evening/?price_min=10000&price_max=25000')"
                        >10000 - 25000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                    <li>
                      <nuxt-link
                        to="/evening/?price_min=25000&price_max=50000"
                        @click.native="menuItemClickHandler('/evening/?price_min=25000&price_max=50000')"
                        >25000 - 50000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                    <li>
                      <nuxt-link
                        to="/evening/?price_min=50000&price_max=1000000"
                        @click.native="menuItemClickHandler('/evening/?price_min=50000&price_max=1000000')"
                        >от 50000<span class="ruble">р</span></nuxt-link
                      >
                    </li>
                  </ul>
                </div>- ->
                  <div class="nav_dropdown_col nav_dropdown_col__proceed">
                    <nuxt-link
                      to="/evening/"
                      @click.native="menuItemClickHandler('/evening/')"
                    >
                      <i /><span>Смотреть<br>полный каталог</span>
                    </nuxt-link>
                  </div>
            </div>
        </div>
        </div>-->

            <nuxt-link
              class="nav_item_link"
              to="/evening/"
              @click.native="menuItemClickHandler('/evening/')"
            >
              Вечерние
            </nuxt-link>
          </li>
          <li class="nav_item nav_item__evening">
            <!--<span class="nav_item_link">Выпускные</span>
            <div class="nav_dropdown nav_dropdown__alter">
              <div class="nav_dropdown_cols">
                <div class="nav_dropdown_col style">
                  <strong class="nav_dropdown_title">Стили</strong>
                  <ul class="nav_dropdown_list nav_dropdown_list__slider">
                    <li v-for="style in promStylesWithPics" :key="style.id">
                      <nuxt-link
                        :to="`/prom/?style=${style.slug}`"
                        @click.native="menuItemClickHandler(`/prom/?style=${style.slug}`)"
                      >
                        <strong>{{ style.name }}</strong>
                        <ins :class="[`nav_style__${style.slug}`]" class="nav_style" />
                      </nuxt-link>
                    </li>
                    <li class="nav_dropdown_addition">
                      <div class="nav_dropdown_addition_title -dark" data-menu="dop_stili_prom">
                        Дополнительные стили
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="nav_dropdown_col_level3">
                  <div data-menu-ref="dop_stili_prom" class="nav_dropdown_col">
                    <ul class="nav_dropdown_list">
                      <li v-for="style in promStylesWithoutPics" :key="style.id" class="nav_dropdown_item">
                        <nuxt-link
                          :to="`/prom/?style=${style.slug}`"
                          @click.native="menuItemClickHandler(`/prom/?style=${style.slug}`)"
                        >
                          {{ style.name }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </div>
                  <div class="nav_dropdown_col brand">
                    <strong class="nav_dropdown_title">Топ бренды</strong>
                    <ul class="nav_dropdown_list">
                      <nuxt-link
                        v-for="brand in promBrands"
                        :key="brand.id"
                        tag="li"
                        :to="`/prom/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/prom/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                      <div class="clear" />
                    </ul>
                  </div>
                  <!- -<div class="nav_dropdown_col">
                  <strong class="nav_dropdown_title">Стоимость</strong>
                  <ul class="nav_dropdown_list">
                    <li>
                      <nuxt-link
                        to="/prom/?price_min=10000&price_max=25000"
                        @click.native="menuItemClickHandler('/prom/?price_min=10000&price_max=25000')"
                      >
                        10000 - 25000<span class="ruble">р</span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/prom/?price_min=25000&price_max=50000"
                        @click.native="menuItemClickHandler('/prom/?price_min=25000&price_max=50000')"
                      >
                        25000 - 50000<span class="ruble">р</span>
                      </nuxt-link>
                    </li>
                    <li>
                      <nuxt-link
                        to="/prom/?price_min=50000&price_max=1000000"
                        @click.native="menuItemClickHandler('/prom/?price_min=50000&price_max=1000000')"
                      >
                        от 50000<span class="ruble">р</span>
                      </nuxt-link>
                    </li>
                  </ul>
                </div>- ->
                <div class="nav_dropdown_col nav_dropdown_col__proceed">
                  <nuxt-link
                    to="/prom/"
                    @click.native="menuItemClickHandler('/prom/')"
                  >
                    <i /><span>Смотреть<br>полный каталог</span>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div> -->

            <nuxt-link
              class="nav_item_link"
              to="/prom/"
              @click.native="menuItemClickHandler('/prom/')"
            >
              Выпускные
            </nuxt-link>
          </li>
          <li
            v-if="accessoriesCategory && accessoriesCategory.active"
            class="nav_item nav_item__wedding"
          >
            <!-- <span class="nav_item_link">Аксессуары</span>
        <div class="nav_dropdown nav_dropdown__simple">
          <div class="nav_dropdown_cols">
            <div class="nav_dropdown_col style">
              <ul class="nav_dropdown_list">
                <li v-for="subCategory in accessoriesCategory.children" :key="subCategory.id">
                  <nuxt-link
                    :to="`/${subCategory.slug}/`"
                    @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                  >
                    {{ subCategory.name }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="nav_dropdown_col nav_dropdown_col__proceed">
              <nuxt-link
                to="/accessories/"
                @click.native="menuItemClickHandler('/accessories/')"
              >
                <i /><span>Смотреть<br>полный каталог</span>
              </nuxt-link>
            </div>
          </div>
        </div> -->

            <nuxt-link
              class="nav_item_link"
              to="/accessories/"
              @click.native="menuItemClickHandler('/accessories/')"
            >
              Аксессуары
            </nuxt-link>
          </li>
          <li
            v-if="outerwearCategory && outerwearCategory.active"
            class="nav_item nav_item__wedding"
          >
            <!-- <span class="nav_item_link">Верхняя одежда</span>
        <div class="nav_dropdown nav_dropdown__simple">
          <div class="nav_dropdown_cols">
            <div class="nav_dropdown_col style">
              <ul class="nav_dropdown_list">
                <li v-for="subCategory in outerwearCategory.children" :key="subCategory.id">
                  <nuxt-link
                    :to="`/${subCategory.slug}/`"
                    @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                  >
                    {{ subCategory.name }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="nav_dropdown_col nav_dropdown_col__proceed">
              <nuxt-link
                to="/outerwear/"
                @click.native="menuItemClickHandler('/outerwear/')"
              >
                <i /><span>Смотреть<br>полный каталог</span>
              </nuxt-link>
            </div>
          </div>
        </div> -->

            <nuxt-link
              class="nav_item_link"
              to="/outerwear/"
              @click.native="menuItemClickHandler('/outerwear/')"
            >
              Верхняя одежда
            </nuxt-link>
          </li>
          <li class="nav_item">
            <nuxt-link
              class="nav_item_link"
              to="/boudoir/"
              @click.native="menuItemClickHandler('/boudoir/')"
            >
              Будуарные
            </nuxt-link>
          </li>
          <li class="nav_item nav_item__wedding">
            <!-- <span class="nav_item_link" style="color: orange;">Распродажа</span>
        <div class="nav_dropdown nav_dropdown__simple">
          <div class="nav_dropdown_cols">
            <div class="nav_dropdown_col style">
              <ul class="nav_dropdown_list">
                <li v-for="subCategory in saleCategory.children" :key="subCategory.id">
                  <nuxt-link
                    :to="`/${subCategory.slug}/`"
                    @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                  >
                    {{ subCategory.name }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
            <div class="nav_dropdown_col nav_dropdown_col__proceed">
              <nuxt-link
                to="/sale/"
                @click.native="menuItemClickHandler('/sale/')"
              >
                <i /><span>Смотреть<br>полный каталог</span>
              </nuxt-link>
            </div>
          </div>
        </div> -->

            <nuxt-link
              class="nav_item_link"
              to="/sale/"
              @click.native="menuItemClickHandler('/sale/')"
            >
              Распродажа
            </nuxt-link>
          </li>
          <li class="nav_item">
            <nuxt-link
              class="nav_item_link"
              to="/reviews/"
              @click.native="menuItemClickHandler('/reviews/')"
            >
              Отзывы
            </nuxt-link>
          </li>
          <li class="nav_item">
            <nuxt-link
              class="nav_item_link"
              to="/contacts/#contacts"
              @click.native="menuItemClickHandler('/contacts/#contacts')"
            >
              Адрес
            </nuxt-link>
          </li>
          <li class="nav_item">
            <nuxt-link
              class="nav_item_link"
              to="/search/"
              @click.native="menuItemClickHandler('/search/')"
            >
              Поиск
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="mobile-menu-footer">
        <ul class="footer_social">
          <li class="footer_social_vk is_static">
            <a href="http://vk.com/loveforeversalon" target="_blank" rel="nofollow" />
          </li>
          <li class="footer_social_tg is_static">
            <a href="https://t.me/SalonLoveForever" target="_blank" rel="nofollow" />
          </li>
        </ul>
        <address class="ancillary_item ancillary_phone" style="vertical-align: top !important;">
          <a href="tel:+74956451908">8 495 645 19 08</a>
        </address>
        <strong class="wrapper_contact_title">
          <a
            class="pseudo js-popup close-catalog-menu"
            data-mfp-decor="mfp-product"
            href="#popup-request"
            @click="$emit('item-clicked')"
          >Заказать обратный звонок</a>
        </strong>
        <p>Мы оперативно перезвоним вам сами!</p>

        <!--<div class="mobile-menu_inner-form">
        <div class="h1">
          Отпаривание или хранение платья в подарок!
        </div>
        <h2>Забирай</h2>
        <form
          action="#"
          class="popup_form popup_form__discount"
          data-validate="parsley"
        >
          <input type="hidden" name="discountValue" value="5" />
          <input type="hidden" name="discountText" value="Получить подарок" />
          <fieldset>
            <label for="discount-name">имя</label>
            <input
              id="discount-name"
              data-error-container=".popup_form_error__name"
              data-error-message="!"
              data-minlength="2"
              data-required="true"
              data-trigger="focusout"
              name="name"
              type="text"
              class="parsley-validated"
            />
            <div class="popup_form_error popup_form_error__name"></div>
          </fieldset>
          <fieldset>
            <label for="discount-mail">e-mail</label>
            <input
              id="discount-mail"
              data-error-container=".popup_form_error__mail"
              data-error-message="!"
              data-minlength="4"
              data-required="true"
              data-trigger="focusout"
              data-type="email"
              name="email"
              type="text"
              class="parsley-validated"
            />
            <div class="popup_form_error popup_form_error__mail"></div>
          </fieldset>
          <fieldset>
            <button type="submit">Оформить подарок</button>
          </fieldset>
          <div class="popup_form_fail">
            Произошла ошибка. Пожалуйста, попробуйте еще раз.
          </div>
        </form>
      </div>-->
      </div>
    </client-only>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBrandsGetterName, getCategoryMapGetters, getStylesGetterName } from '@/helpers/utils'
import { CATEGORY } from '@/assets/js/constants'
import Bus from '../../assets/js/bus'

export default {
  name: 'MobileMenu',
  computed: {
    ...mapGetters(getCategoryMapGetters([CATEGORY.WEDDING, CATEGORY.EVENING, CATEGORY.PROM], { styles: true, brands: true })),
    saleCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === 'sale')
    },
    accessoriesCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === 'accessories')
    },
    outerwearCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === 'outerwear')
    },
    categoriesTree () {
      return this.$store.getters.tree
    },
    weddingStylesWithPics () {
      return this.weddingStyles.filter((style) => {
        return style.with_pic
      })
    },
    weddingStylesWithoutPics () {
      return this.weddingStyles.filter((style) => {
        return !style.with_pic
      })
    },
    eveningStylesWithPics () {
      return this.eveningStyles.filter((style) => {
        return style.with_pic
      })
    },
    eveningStylesWithoutPics () {
      return this.eveningStyles.filter((style) => {
        return !style.with_pic
      })
    },
    promStylesWithPics () {
      return this.promStyles.filter((style) => {
        return style.with_pic
      })
    },
    promStylesWithoutPics () {
      return this.promStyles.filter((style) => {
        return !style.with_pic
      })
    },
    weddingBrands () {
      return this[getBrandsGetterName(CATEGORY.WEDDING)]
    },
    eveningBrands () {
      return this[getBrandsGetterName(CATEGORY.EVENING)]
    },
    promBrands () {
      return this[getBrandsGetterName(CATEGORY.PROM)]
    },
    weddingStyles () {
      return this[getStylesGetterName(CATEGORY.WEDDING)]
    },
    eveningStyles () {
      return this[getStylesGetterName(CATEGORY.EVENING)]
    },
    promStyles () {
      return this[getStylesGetterName(CATEGORY.PROM)]
    }
  },
  methods: {
    menuItemClickHandler (path) {
      if (this.$route.fullPath !== path) {
        Bus.$emit('menu-item-click', path)
        this.$emit('item-clicked')
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss">
.nav_dropdown_addition_title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align-last: center;
  font-size: 13px;
  font-family: 'ProxySB', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  background-color: #b33971;

  &.-dark {
    background-color: #733166;
  }
}
</style>
