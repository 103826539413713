<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="fitting-widget">
    <client-only>
      <div class="fitting-widget__logo-container">
        <a class="fitting-widget__arrow arrow-left" />

        <div class="fitting-widget__logo">
          <img src="/img/fitting_widget/logo.png" alt="Логотип salon-love-forever.ru">
        </div>
      </div>

      <div class="fitting-widget__content-container">
        <div class="fitting-widget__content">
          <div class="fitting-widget__header">
            <div class="fitting-widget__steps">
              <div class="fitting-widget__step active current" data-slide="slide-form">
                <a class="fitting-widget__step-link">
                  <span class="fitting-widget__step-desktop">1. Контакты</span>
                  <span class="fitting-widget__step-mobile">Шаг 1</span>
                </a>
              </div>
              <!--              <div class="fitting-widget__step" data-slide="slide-salon">
                <a class="fitting-widget__step-link">
                  <span class="fitting-widget__step-desktop">2. Салон</span>
                  <span class="fitting-widget__step-mobile">Шаг 2</span>
                </a>
              </div>-->
              <div class="fitting-widget__step" data-slide="slide-date">
                <a class="fitting-widget__step-link">
                  <span class="fitting-widget__step-desktop">2. Время</span>
                  <span class="fitting-widget__step-mobile">Шаг 2</span>
                </a>
              </div>
            </div>

            <div class="fitting-widget__slide-header-container">
              <!--              <div class="fitting-widget__slide-header" data-slide="slide-salon">
                <div class="fitting-widget-header__title">
                  Выберите салон
                </div>
              </div>-->
              <div class="fitting-widget__slide-header" data-slide="slide-date">
                <div class="fitting-widget-header__title">
                  Выберите дату и время
                </div>
                <div class="fitting-widget-header__salon" v-html="fittingSalonLabel" />
              </div>
              <div class="fitting-widget__slide-header" data-slide="slide-time">
                <div class="fitting-widget-header__title">
                  Выберите дату и время
                </div>
                <div class="fitting-widget-header__salon" v-html="fittingSalonLabel" />
              </div>
              <div class="fitting-widget__slide-header" data-slide="slide-send">
                <div class="fitting-widget-header__title">
                  Отправка заявки
                </div>
                <div class="fitting-widget-header__salon" v-html="fittingSalonLabel" />
                <div class="fitting-widget-header__text" />
              </div>
              <div class="fitting-widget__slide-header active" data-slide="slide-form">
                <div class="fitting-widget-header__title">
                  Контактные данные
                </div>
              </div>
            </div>
          </div>

          <div class="fitting-widget__slider">
            <div class="fitting-widget__slide slide-form active current" data-slide="slide-form">
              <div class="fitting-widget__form">
                <form>
                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__date" />
                    <div class="fitting-widget-form__title-comment">
                      Бесплатная примерка
                    </div>
                  </div>

                  <div class="fitting-widget-form__row checkboxes-row">
                    <div v-if="weddingCategory" class="fitting-widget-form__checkbox">
                      <input
                        id="fitting-widget-product-type1"
                        v-model="checkedCategories"
                        :value="weddingCategory.id"
                        type="checkbox"
                      >
                      <label for="fitting-widget-product-type1">{{ weddingCategory.name }}</label>
                    </div>

                    <div v-if="eveningCategory" class="fitting-widget-form__checkbox">
                      <input
                        id="fitting-widget-product-type2"
                        v-model="checkedCategories"
                        :value="eveningCategory.id"
                        type="checkbox"
                      >
                      <label for="fitting-widget-product-type2">{{ eveningCategory.name }}</label>
                    </div>

                    <div v-if="promCategory" class="fitting-widget-form__checkbox second-row">
                      <input
                        id="fitting-widget-product-type3"
                        v-model="checkedCategories"
                        :value="promCategory.id"
                        type="checkbox"
                      >
                      <label for="fitting-widget-product-type3">{{ promCategory.name }}</label>
                    </div>

                    <div class="fitting-widget-form__checkbox-tooltip">
                      <div class="fitting-widget-form__checkbox-tooltip-inner">
                        Выберите хотя бы один пункт
                      </div>
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container">
                      <label for="fitting-widget-name" class="fitting-widget-form__label">Имя</label>
                      <input
                        id="fitting-widget-name"
                        v-model="userName"
                        type="text"
                        name="user_name"
                        class="fitting-widget-form__input"
                      >
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container">
                      <label for="fitting-widget-phone" class="fitting-widget-form__label">Телефон</label>
                      <input
                        id="fitting-widget-phone"
                        v-phone="userPhone"
                        type="tel"
                        autocomplete="tel"
                        name="user_phone"
                        class="fitting-widget-form__input"
                        placeholder="+7 (___) ___ __ __"
                      >
                      <a class="fitting-widget-form__info" />
                      <div class="fitting-widget-form__tooltip">
                        Ваш телефонный номер в полной безопасности. Мы не будем отправлять вам смс-рассылки и никому не
                        передадим ваш номер телефона. Он будет использован только для того, чтобы отправить вам
                        уведомление о записи на примерку.
                      </div>
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container">
                      <label for="fitting-widget-email" class="fitting-widget-form__label">E-mail</label>
                      <input
                        id="fitting-widget-email"
                        v-model="userEmail"
                        type="text"
                        name="user_email"
                        class="fitting-widget-form__input"
                      >
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container fitting-widget-form__datepicker-container">
                      <label for="fitting-widget-date" class="fitting-widget-form__label">Дата свадьбы</label>
                      <div class="fitting-widget-form__input-wrapper">
                        <input
                          id="fitting-widget-date"
                          :value="weddingDateFormatted"
                          type="text"
                          name="user_wedding_date"
                          class="fitting-widget-form__input fitting-widget-form__datepicker-input"
                          placeholder="Дата не определена"
                        >
                        <a class="fitting-widget-form__datepicker-toggler" />
                      </div>

                      <div class="fitting-widget-form__datepicker" />
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <YandexSmartCaptcha
                        v-if="isWidgetOpened"
                        sitekey="ysc1_5DCdNVq9VaZHFe6Q7aj6veTyQOBtqsyEIBMODEPI56a7f441"
                        :on-success="captchaOnSuccess"
                    />
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__submit">
                      <button type="button" name="continue" class="fitting-widget-form__submit-button button" @click="continueWidgetFilling">
                        <span>Продолжить</span>
                      </button>
                    </div>
                    <p class="privacy-policy-msg">
                      Нажимая на кнопку, вы даёте согласие на обработку персональных данных и соглашаетесь с
                      <nuxt-link to="/privacy" target="_blank">
                        политикой конфиденциальности
                      </nuxt-link>.
                    </p>
                  </div>

                  <div class="fitting-widget__bonus">
                    <div class="fitting-widget-bonus__top">
                      <div class="fitting-widget-bonus__title">
                        Дарим приятные подарки
                      </div>
                      <div class="fitting-widget-bonus__comment">
                        При покупке свадебного платья
                      </div>
                    </div>

                    <div class="fitting-widget__advantages">
                      <div class="fitting-widget-advantages__title">
                        Наши преимущества
                      </div>
                      <div class="fitting-widget-advantages__item">
                        более
                        <em>5 000 свадебных и вечерних</em> платьев от топовых дизайнеров
                      </div>
                      <div class="fitting-widget-advantages__item">
                        <em>удобное расположение</em> салонов
                      </div>
                      <div class="fitting-widget-advantages__item">
                        бесплатные <em>примерки</em>
                      </div>
                      <div class="fitting-widget-advantages__item">
                        <em>подгонка</em> по длине и фигуре осуществляется портными в
                        <em>салоне</em>
                      </div>
                      <!--                      <div class="fitting-widget-advantages__item">
                        бесплатное <em>отпаривание</em> платья
                      </div>-->
                      <div class="fitting-widget-advantages__item">
                        бесплатная <em>парковка</em>
                      </div>
                      <div class="fitting-widget-advantages__item">
                        удобные <em>лаундж-зоны</em> для ожидающих вас гостей
                      </div>
                      <div class="fitting-widget-advantages__item">
                        большие <em>зеркала</em> и настоящий
                        <em>подиум!</em>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!--            <div class="fitting-widget__slide slide-salon" data-slide="slide-salon">
              <div v-for="salon in salons" :key="salon.id" class="fitting-widget__salon">
                <form @submit.prevent>
                  <div class="fitting-widget-salon__title">
                    <span class="fitting-widget-salon__title-main">{{ salon.name }}</span>
                  </div>
                  <div class="fitting-widget-salon__address">
                    {{ salon.address }}
                  </div>
                  <div class="fitting-widget-salon__phone">
                    {{ salon.phone }}
                  </div>
                  <div class="fitting-widget-salon__worktime">
                    пн-вс 10:00-22:00
                  </div>
                  <a
                    :href="salon.yandex_map_link"
                    target="_blank"
                    class="fitting-widget-salon__address-link"
                    rel="nofollow"
                  >Адрес на карте</a>
                  <button type="submit" class="fitting-widget-salon__button button" @click="salonChooseHandler(salon)">
                    <span class="fitting-widget-salon__button-desktop">Нажать для записи</span>
                    <span class="fitting-widget-salon__button-mobile">Выбрать</span>
                  </button>
                </form>
              </div>
            </div>-->

            <client-only>
              <div class="fitting-widget__slide slide-date" data-slide="slide-date">
                <div class="fitting-widget__datepicker" />

                <div class="fitting-widget__busy-tooltip">
                  Упс, все время занято.<br>Пожалуйста, выберите другую дату :)
                </div>

                <div class="fitting-widget__date-contacts">
                  <div class="fitting-widget__contacts">
                    <div class="fitting-widget-contacts__address">
                      {{ currentSalon.address }}
                    </div>
                    <div class="fitting-widget-contacts__phone">
                      {{ currentSalon.phone }}
                    </div>
                    <br>
                    <div class="fitting-widget-contacts__worktime">
                      пн-вс 10:00-22:00
                    </div>
                    <div class="fitting-widget-contacts__link-container">
                      <a
                        :href="currentSalon.yandex_map_link"
                        target="_blank"
                        class="fitting-widget-contacts__address-link"
                      >Адрес на карте</a>
                    </div>
                  </div>
                </div>
              </div>
            </client-only>

            <div class="fitting-widget__slide slide-time" data-slide="slide-time">
              <div class="fitting-widget__time-slider">
                <div class="fitting-widget-time-slider__month" />

                <div class="fitting-widget-time-slider__slider owl-carousel owl-theme" />
              </div>

              <div class="fitting-widget__time-table" />

              <div class="fitting-widget__date-contacts" />
            </div>

            <div class="fitting-widget__slide slide-form" data-slide="slide-send">
              <div class="fitting-widget__form">
                <form>
                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__date" />
                  </div>

                  <ul class="fitting-widget__categories">
                    <li v-for="checkedCategory in checkedCategories" :key="checkedCategory">
                      <span>{{ categoriesNames[checkedCategory] }}</span>
                    </li>
                  </ul>
                  <br><br>
                  <h3>Контактная информация</h3>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container">
                      {{ userName }}
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container">
                      {{ userPhone }}
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__input-container">
                      {{ userEmail }}
                    </div>
                  </div>

                  <div class="fitting-widget-form__row">
                    <div class="fitting-widget-form__submit">
                      <button type="button" name="continue" class="fitting-widget-form__submit-button button" @click="sendBookingData">
                        <span>Отправить</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fitting-widget__success">
        <div class="fitting-widget-success__title">
          Спасибо!
        </div>
        <div class="fitting-widget-success__title-comment">
          Запись успешно создана
        </div>
        <div class="fitting-widget-success__salon">
          Вы записаны в Свадебный салон<br>Love Forever —
          {{ currentSalon.name }}
        </div>
        <div class="fitting-widget-success__text">
          <div class="fitting-widget-contacts__address">
            {{ currentSalon.address }}
          </div>
          <div class="fitting-widget-contacts__phone">
            {{ currentSalon.phone }}
          </div>
          <br>
          <div class="fitting-widget-contacts__worktime">
            пн-вс 10:00-22:00
          </div>
          <div class="fitting-widget-contacts__link-container">
            <a
              :href="currentSalon.yandex_map_link"
              target="_blank"
              class="fitting-widget-contacts__address-link"
            >Адрес на карте</a>
          </div>
        </div>
        <div class="fitting-widget-success__date">
          {{ fullCurrentDateTime }}
        </div>
        <div class="fitting-widget-success__date-comment">
          Бесплатная примерка
        </div>
        <a
          class="fitting-widget-success__questions pseudo js-popup js-request"
          data-mfp-decor="mfp-product"
          href="#popup-request"
          @click="anyQuestionLinkClickHandler"
        >Остались вопросы?</a>
      </div>

      <div class="fitting-widget__error">
        <div class="fitting-widget__error__title">
          Запись не создана
        </div>
        <div class="fitting-widget__error__title-comment">
          К сожалению выбранное вами время уже недоступно. Попробуйте выбрать другое
        </div>
        <a
          class="fitting-widget__error__title-link"
          href="javascript:void(0);"
          @click="backToTimeChooseSlide"
        >Выбрать новое время</a>
      </div>

      <div class="popup-loader-container">
        <div class="popup__loader" />
      </div>
    </client-only>
  </div>
</template>

<script>
import datepickerFactory from 'jquery-datepicker'
import datepickerRUFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-ru'
import $ from 'jquery'
import { format } from 'date-fns'
import YandexSmartCaptcha from '@/components/YandexSmartCaptcha.vue'
import analyticsMixin from '../../assets/mixins/analytics.mixin'
import Bus from '../../assets/js/bus'
import { CLOSE_OFFCANVAS_EVENT } from '../../assets/js/constants'

const INTERVAL_REGISTRATION = 60

const SLIDE_DATE = 'slide-date'
const SLIDE_TIME = 'slide-time'
// const SLIDE_SALON = 'slide-salon' // выбор салона отключен
const SLIDE_FORM = 'slide-form'
const SLIDE_SEND = 'slide-send'

const MOSCOW_TIMEZONE_DELTA = 180
const timezoneOffset = new Date().getTimezoneOffset()
const timezoneOffsetDelta = (-timezoneOffset - MOSCOW_TIMEZONE_DELTA) / 60

export default {
  name: 'FittingWidget',
  components: { YandexSmartCaptcha },
  mixins: [analyticsMixin],
  props: {
    source: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      captchaToken: null,
      isWidgetOpened: false,
      slidesAnimating: false,
      slideEasing: 'swing',
      slideDuration: 800,
      busyDays: [], // Массив с занятыми днями, заполняется после выбора салона внутри коллбека $.get
      todayDate: new Date(), // Сегодняшняя дата
      currentDate: null, // Текущая выбранная в календаре дата типа Date
      currentDateText: '', // Текущая выбраннвя в календаре дата в формате строки
      dayNamesMin: null, // Переменная для хранения списка названий дней недели на русском
      monthNames: null, // Переменная для хранения списка названий месяец на русском

      currentSalon: { name: '' },

      userName: '',
      userPhone: '',
      userEmail: '',
      userWeddingDate: null,

      // Переменные для расписания, озобначаются после выбора салона в коллбеке $.get
      scheduleHourFrom: 0,
      scheduleHourTo: 23,

      visibleItemsNum: 5, // Сколько показывать элементов в слайдере

      itemClass: 'fitting-widget-time-slider__item',
      sliderStartPosition: null, // Стартовая позиция для слайдера, изначально включены пустые дни

      $offcanvasMenu: null,
      $offcanvasInner: null,
      $body: null,
      $datepicker: null,
      $busyTooltip: null,
      currentTime: '--', // Выбранное в слайде время, передается потом в слайд с формой
      $owl: null,

      $form: null,
      $formDateInput: null,
      $formDatepicker: null,
      $formDatepickerContainer: null,

      draftBooking: null,

      checkedCategories: [],
      SLIDE_TIME
    }
  },
  computed: {
    categoriesNames () {
      return {
        [this.promCategory.id]: this.promCategory.name,
        [this.weddingCategory.id]: this.weddingCategory.name,
        [this.eveningCategory.id]: this.eveningCategory.name
      }
    },
    fullCurrentDateTime () {
      if (!this.currentDate || !this.currentDateText || !this.currentTime || !this.dayNamesMin) {
        return ''
      }

      return this.currentDateText + ' (' + this.dayNamesMin[this.currentDate.getDay()] + ') в ' + this.currentTime
    },
    weddingDateFormatted () {
      return this.userWeddingDate ? format(this.userWeddingDate, 'dd.MM.yyyy') : ''
    },
    fittingSalonLabel () {
      return `Запись в Свадебный салон<br />Love Forever —
                  ${this.currentSalon.name}`
    },
    isWorkingDayEnded () {
      return this.todayDate.getHours() + 1 >= this.scheduleHourTo // Закончился ли текущий рабочий день
    },
    favoriteItems () {
      return this.$store.state.favorite.items
    },
    visibleItemsHalf () {
      return Math.floor(this.visibleItemsNum / 2)
    },
    salons () {
      return this.$store.state.salons
    },
    categories () {
      return this.$store.state.categories
    },
    salonNumber () {
      const salonsMap = { 'м. Октябрьская': 1 }
      return salonsMap[this.currentSalon.name]
    },
    weddingCategory () {
      if (!this.$store.state.categories) {
        return null
      }

      return this.$store.state.categories.find(category => category.slug === 'wedding')
    },
    eveningCategory () {
      if (!this.$store.state.categories) {
        return null
      }
      return this.$store.state.categories.find(category => category.slug === 'evening')
    },
    promCategory () {
      if (!this.$store.state.categories) {
        return null
      }
      return this.$store.state.categories.find(category => category.slug === 'prom')
    }
  },
  mounted () {
    this.todayDate.setHours(this.todayDate.getHours() + timezoneOffsetDelta)
    datepickerFactory($)
    datepickerRUFactory($)
    require('jquery-validation')

    Bus.$on(CLOSE_OFFCANVAS_EVENT, this.closeCanvasHandler)

    Bus.$on('FittingWidgetOpened', () => {
      this.isWidgetOpened = true
    })

    Bus.$on('FittingWidgetClosed', () => {
      this.isWidgetOpened = false
    })

    setTimeout(() => {
      const vm = this
      // Кэширование jquery элементов
      this.$body = $('body')
      this.$offcanvasMenu = $('.offcanvas__menu')
      this.$offcanvasInner = $('.offcanvas-menu__inner')

      if (window) {
        this.visibleItemsNum = $(window).width() > 480 ? 7 : 5
      }

      // Нажатие по стрелкам
      $('.fitting-widget__arrow').click(function () {
        if (!$(this).hasClass('active')) {
          return
        }

        const $activeSlide = $('.fitting-widget__slide.current')
        if ($(this).hasClass('arrow-left')) {
          vm.changeSlide($activeSlide.prev().data('slide'))
        } else if ($(this).hasClass('arrow-right')) {
          vm.changeSlide($activeSlide.next().data('slide'))
        }
      })

      // Нажатие по шагам
      $('.fitting-widget__step').click(function () {
        if (!$(this).hasClass('active')) {
          return
        }

        const slideData = $(this).data('slide')
        if (!$(this).hasClass('current') || $(this).hasClass('current-active')) {
          vm.changeSlide(slideData)
        }
      })

      // Слайд выбора времени
      this.currentTime = '--' // Выбранное в слайде время, передается потом в слайд с формой
      this.$owl = $('.fitting-widget-time-slider__slider')

      // Выбор часа
      $('.fitting-widget__slide.slide-time').on('click', '.fitting-widget-time__item', function () {
        if ($(this).hasClass('full-busy') || $(this).hasClass('past-hour')) {
          return
        } // Переходим к форме если час не полность занят

        vm.pushEvent({
          event: 'time_widget_' + vm.salonNumber
        })

        // Добавление текста в заголовок слайда
        /* $('.fitting-widget-header__text').html(
                  $(
                    '.fitting-widget__slide.slide-time .fitting-widget__date-contacts'
                  ).html()
                ) */

        // Добавление текста даты
        vm.currentTime = $(this).data('hour')
        vm.currentDate.setHours(Number(vm.currentTime.split(':')[0]) + timezoneOffsetDelta)
        vm.draftBooking.time_temp = vm.currentDate.toISOString()

        vm.$axios
          .put(`/bookings/${vm.draftBooking.id}`, vm.draftBooking)
          .then((response) => {
            vm.draftBooking = response.data
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('error', error.message)
          })

        $('.fitting-widget-form__date').text(vm.fullCurrentDateTime)

        // Смена слайда на слайд формы
        vm.changeSlide(SLIDE_SEND)
      })
      /* .on('click', '.owl-item', function() {
          // Прокручивание карусели к выбранному дню
          const index = $(this).index()
          vm.$owl.trigger('to.owl.carousel', [index - 3, 300, true])
        }) */

      // Слайд с формой
      this.$formDateInput = $('.fitting-widget-form__datepicker-input')
      this.$formDatepicker = $('.fitting-widget-form__datepicker')
      this.$formDatepickerContainer = $('.fitting-widget-form__datepicker-container')
      // Скрытие/показ календаря
      this.$formDateInput.focus(function () {
        vm.$formDatepickerContainer.addClass('active')
        vm.$formDatepicker.slideDown()
        // vm.scrollToDatepicker()
      })
      $('.fitting-widget-form__datepicker-toggler').click(function () {
        vm.$formDatepickerContainer.toggleClass('active')
        vm.$formDatepicker.slideToggle()
        // vm.scrollToDatepicker()
      })

      // Инициализация календаря в форме
      this.$formDatepicker.datepicker({
        showOtherMonths: true,
        selectOtherMonths: true,
        minDate: 0,
        navigationAsDateFormat: true,
        prevText: 'MM',
        nextText: 'MM',
        onSelect: (date) => {
          vm.userWeddingDate = this.$formDatepicker.datepicker('getDate')
          vm.$formDatepickerContainer.removeClass('active')
          vm.$formDatepicker.slideUp()
          // $form.validate().element('#fitting-widget-date'); //Валидация инпута даты после ее выбора в календаре
        }
      })

      // Валидация формы
      // валидация ФИО
      $.validator.addMethod('validFio', function (value) {
        if (value === '') {
          return true
        }
        return /^([а-яА-Яa-zA-Z-]+\s)*[а-яА-Яa-zA-Z-\s]+$/.test(value)
      })

      // валидация телефона
      $.validator.addMethod('validPhone', function (value) {
        if (value === '') {
          return true
        }
        return value.length > 8
        // return /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i.test(value);
      })

      $.validator.addMethod('validEmail', function (value) {
        if (value === '') {
          return true
        }
        return /^([a-zA-Z0-9]+[._-]?)+([a-zA-Z0-9]+[_]?)*@([a-zA-Z0-9]+[-]?)+(\.[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})$/.test(
          value
        )
      })

      // Тексты ошибок
      $.extend($.validator.messages, {
        required: 'Это обязательное поле',
        validFio: 'Некорректно введено имя',
        validEmail: 'Введите корректный e-mail',
        validPhone: 'Введите корректный номер телефона',
        minlength: 'Введите корректный номер телефона'
      })

      // Форма
      this.$form = $('.fitting-widget__form form')
      const $phone = this.$form.find('[name="user_phone"]')
      const minPhoneLength = $phone.length ? 18 : 8

      this.$form.validate({
        rules: {
          user_name: {
            required: true,
            validFio: true,
            normalizer (value) {
              return $.trim(value)
            }
          },
          user_phone: {
            required: true,
            validPhone: true,
            minlength: minPhoneLength // В маске у телефона ровно 18 символов
          },
          user_email: {
            required: false,
            normalizer (value) {
              return $.trim(value)
            }
          }
        }
      })

      // При выборе чекбокса, скрытие напоминание и сброс ошибок
      this.$form.find('.checkboxes-row input').change(function () {
        if ($(this).is(':checked')) {
          $(this).removeClass('error')
          $('.fitting-widget-form__checkbox-tooltip')
            .stop()
            .slideUp()
        }
      })

      // Скрытие календаря в форме при убирании фокуса с этого блока
      this.$body.click(function (e) {
        const $target = $(e.target)

        if (
          vm.$formDatepickerContainer &&
          vm.$formDatepickerContainer.hasClass('active') &&
          !$target.closest('.fitting-widget-form__datepicker-container').length &&
          !$target.closest('.ui-corner-all').length
        ) {
          vm.$formDatepickerContainer.removeClass('active')
          vm.$formDatepicker.slideUp()
        }
      })
    }, 2)
  },
  methods: {
    captchaOnSuccess (token) {
      this.captchaToken = token
    },
    anyQuestionLinkClickHandler () {
      this.$emit('close')
    },
    sendPersonData () {
      const newBooking = {
        name: this.userName,
        phone: this.userPhone,
        email: this.userEmail,
        favorite: this.favoriteItems,
        categories: this.checkedCategories,
        wedding_date: this.userWeddingDate ? new Date(this.userWeddingDate).toISOString() : null,
        token: this.captchaToken
      }

      this.$axios
        .post('/bookings', newBooking)
        .then((response) => {
          this.draftBooking = response.data
          this.pushEvent({
            event: 'request_widget_person'
          })
          // this.changeSlide(SLIDE_SALON) // пропускаем шаг и сразу дергаем выбор салона
          this.salonChooseHandler(this.salons[0])
        })
    },
    sendBookingData () {
      const vm = this

      this.draftBooking.date = vm.currentDate.toISOString()
      this.draftBooking.approved = true

      const $loader = $('.fitting-widget .popup-loader-container')
      $loader.addClass('loader-show')

      const $content = $('.fitting-widget__content')
      const $success = $('.fitting-widget__success')
      const $error = $('.fitting-widget__error')

      this.$axios
        .put(`/bookings/${this.draftBooking.id}`, this.draftBooking)
        .then((response) => {
          // Скрытие прелоадера
          vm.pushEvent({
            event: 'request_widget_' + vm.salonNumber
          })

          if (this.source) {
            this.pushEvent({ event: 'request_widget-' + this.source })
          }

          $loader.addClass('loader-fade')
          setTimeout(function () {
            $loader.removeClass('loader-fade loader-show')
          }, 250)

          $('.fitting-widget__arrow').removeClass('active')
          $content.addClass('inactive')
          $success.addClass('animating')

          $('.fitting-widget')
            .stop()
            .animate(
              {
                height: $('.fitting-widget__logo-container').outerHeight() + $success.outerHeight()
              },
              vm.slideDuration,
              vm.slideEasing,
              function () {
                $(this).height('auto')
                $content.hide()
                $success.removeClass('animating').addClass('active')
              }
            )
        })
        .catch(async (error) => {
          if (error.response.status === 406) {
            await vm.updateBusyDays()
          }

          $loader.addClass('loader-fade')
          setTimeout(function () {
            $loader.removeClass('loader-fade loader-show')
          }, 250)

          $('.fitting-widget__arrow').removeClass('active')
          $content.addClass('inactive')
          $error.addClass('animating')

          $('.fitting-widget')
            .stop()
            .animate(
              {
                height: $('.fitting-widget__logo-container').outerHeight() + $error.outerHeight()
              },
              vm.slideDuration,
              vm.slideEasing,
              function () {
                $(this).height('auto')
                $content.hide()
                $error.removeClass('animating').addClass('active')
              }
            )
        })
    },
    backToTimeChooseSlide () {
      this.timeSliderInit(this.currentDateText)
      this.changeSlide(SLIDE_TIME)
      const $content = $('.fitting-widget__content')
      const $error = $('.fitting-widget__error')
      $error.addClass('inactive').hide()
      $content
        .removeClass('inactive')
        .addClass('animating')
        .show()

      $('.fitting-widget')
        .stop()
        .animate(
          {
            height: $('.fitting-widget__logo-container').outerHeight() + $content.outerHeight()
          },
          this.slideDuration,
          this.slideEasing,
          function () {
            $(this).height('auto')
            $error.hide()
            $content.removeClass('animating').addClass('active')
          }
        )
    },
    closeCanvasHandler () {
      this.changeSlide(SLIDE_FORM)
    },
    async updateBusyDays () {
      this.busyDays = await this.$axios.$get(`/salons/${this.currentSalon.id}/busy_days`, {
        headers: {
          'Cache-Control': 'no-cache'
        }
      })
    },
    continueWidgetFilling () {
      if (this.$form.valid() && this.captchaToken) {
        this.sendPersonData()
      }
    },
    async salonChooseHandler (salon) {
      this.currentSalon = salon

      this.draftBooking.salon = this.currentSalon.id

      this.$axios
        .put(`/bookings/${this.draftBooking.id}`, this.draftBooking)
        .then((response) => {
          this.draftBooking = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('error', error.message)
        })

      await this.updateBusyDays()

      this.scheduleHourFrom = parseInt(this.currentSalon.default_open_hour)
      this.scheduleHourTo = parseInt(this.currentSalon.default_close_hour)

      this.datepickerInit() // Инициализация календаря

      this.pushEvent({ event: 'push_widget_' + this.salonNumber })

      // Смена слайда на слайд даты
      this.changeSlide(SLIDE_DATE)
    },
    scrollToDatepicker () {
      this.$offcanvasMenu.stop().animate({ scrollTop: this.$formDatepicker.offset().top }, 300)
    },
    scheduleShow (date) {
      let listHtml = '' // Хтмл для всех элементов списка
      const itemClass = 'fitting-widget-time__item'
      let isFullyBusy = false // Переменная, указываающая полностью ли занят текущий период
      const currentBusyDate = this.busyDays[date] || null // Выбранный день в списке занятых дней
      const hoursLength = this.scheduleHourTo - this.scheduleHourFrom // Количество часов работы салона
      const mWorkTime = hoursLength * 60 // Рабочее время в минутах
      const intervalCount = ~~(mWorkTime / INTERVAL_REGISTRATION) // Количество интервалов в рабочем времени

      for (let i = 0; i < intervalCount; i++) {
        let timeText
        const myTime = new Date(0, 0, 0, 0, this.scheduleHourFrom * 60 + INTERVAL_REGISTRATION * i, 0, 0)
        let myMinutes = myTime.getMinutes()

        if (myMinutes < 10) { myMinutes = '0' + myMinutes }

        const timeHours = myTime.getHours()
        const resultTimeString = timeHours + ':' + myMinutes
        let itemAdditionalClass = '' // Класс, добавляющийся элементу если он полностью занят

        // Определение, прошел ли час если текущий день
        if (this.currentDate.toDateString() === this.todayDate.toDateString()) {
          if (
            (this.todayDate.getHours() === myTime.getHours() && this.todayDate.getMinutes() >= myTime.getMinutes()) ||
            this.todayDate.getHours() > myTime.getHours()
          ) {
            itemAdditionalClass = 'past-hour'
          }
        }

        if (itemAdditionalClass !== 'past-hour') {
          let currentBusyRooms = 1 // Количество занятых примерочных в текущем периоде

          if (currentBusyDate && currentBusyDate !== 'full') {
            currentBusyRooms = currentBusyDate[timeHours] || 1 // Если в текущем дне есть занятые примерочные, вычисление количества занятых комнат
          }

          if (currentBusyDate === null) { // Если все часы заняты
            timeText = 'Записаться на примерку'
          } else if (currentBusyDate === 'full') {
            isFullyBusy = true
            timeText = '<div class="fitting-widget-time__full-busy" style="height: ' + intervalCount * 40 + 'px"><div>' // высота береться такая же как и у других дней
            timeText += 'Всё время занято.<br>Пожалуйста, выберите другую дату'
            timeText += '</div></div>'
          } else if (currentBusyRooms === 'full') {
            timeText = 'Все примерочные заняты.'
            itemAdditionalClass = ' full-busy'
          } else {
            timeText = 'Записаться на примерку'
          }

          // Подготовка html к добавлению в общий список
          // Если что-то свободно
          if (!isFullyBusy) {
            listHtml +=
              '<div class="' + itemClass + ' ' + itemAdditionalClass + '" data-hour="' + resultTimeString + '">'
            listHtml += '<div class="fitting-widget-time__number">' + resultTimeString + '</div>'
            listHtml += '<div class="fitting-widget-time__text">' + timeText + '</div>'
            listHtml += '</div>'
          } else { // Если все занято
            listHtml = timeText
          }
        }
      }

      $('.fitting-widget__time-table').html(listHtml)
    },
    changeSlide (nextSlide) {
      // nextSlide - открывающийся слайд, идентифицируется по атрибуту data-slide
      const vm = this
      if (this.slidesAnimating) {
        return
      }

      this.slidesAnimating = true
      const dataAttr = '[data-slide="' + nextSlide + '"]'
      const $nextSlide = $('.fitting-widget__slide' + dataAttr)

      // Смена шагов
      $('.fitting-widget__step.current').removeClass('current current-active')
      $('.fitting-widget__step' + dataAttr).addClass('active current')
      if (nextSlide === SLIDE_TIME) {
        $('.fitting-widget__step[data-slide="slide-date"]').addClass('active current current-active')
      }
      $('.fitting-widget__step')
        .slice($('.fitting-widget__step.current').index() + 1)
        .removeClass('active') // Убираем активность у шагов после текущего (для того чтобы нельзя было перейти с текущего на следующий шаг)

      // Смена заголовка под шагами
      const $nextHeader = $('.fitting-widget__slide-header' + dataAttr)
      $('.fitting-widget__slide-header.active')
        .stop()
        .fadeOut(vm.slideDuration, function () {
          const $currentHeader = $(this)

          $nextHeader
            .addClass('animating')
            .stop()
            .animate({}, vm.slideDuration, function () {
              $currentHeader.removeClass('active').show()
              $nextHeader.addClass('active').removeClass('animating')
            })
        })

      // Смена высоты контента в offcanvas
      $('.fitting-widget__slider')
        .stop()
        .animate({ height: $nextSlide.outerHeight() }, this.slideDuration, this.slideEasing, function () {
          $(this).height('auto')
        })

      // Ресет формы если уйти с нее на предыдущие шаги
      if ($('.fitting-widget__slide:last-child').hasClass('current')) {
        this.$form[0].reset()
        this.$form.find('input').removeClass('error') // Сброс валидации формы
      }

      // Анимация слайдов

      $('.fitting-widget__slide.current')
        .stop()
        .fadeOut(this.slideDuration, this.slideEasing, function () {
          const $currentSlide = $(this)

          setTimeout(function () {
            $nextSlide
              .addClass('animating')
              .stop()
              .animate({}, vm.slideDuration, function () {
                vm.slidesAnimating = false
                $currentSlide.removeClass('current').show()
                $nextSlide.addClass('current active').removeClass('animating')
              })
          }, 300)

          // Скрытие/показ стрелочек
          if (nextSlide === SLIDE_FORM) {
            // Если первый слайд
            $('.fitting-widget__arrow.arrow-left').removeClass('active')
          } else {
            $('.fitting-widget__arrow.arrow-left').addClass('active')
          }
          // Правая стрелка не показывается
          // if (nextSlide === SLIDE_FORM //Если последний слайд (слайд формы)
          //     || !$('.fitting-widget__slide.current').next().hasClass('active')) {
          //     $('.fitting-widget__arrow.arrow-right').removeClass('active');
          // } else {
          //     $('.fitting-widget__arrow.arrow-right').addClass('active');
          // }
        })
    },
    dateFormat (date) {
      // Приведение даты к формату дат, приходящих из json файла
      return $.datepicker.formatDate('dd.mm.yy', date)
    },
    datepickerInit () {
      const vm = this
      this.$datepicker = $('.fitting-widget__datepicker')

      // Показ подсказки если на выбранном дне все занято
      this.$busyTooltip = $('.fitting-widget__busy-tooltip')

      this.$busyTooltip.appendTo('body')

      this.$datepicker
        .on('mouseenter', '.ui-datepicker-calendar td.busy', function () {
          vm.$busyTooltip.addClass('active').css({
            // Показываем тултип ровно над выбранной датой
            top: $(this).offset().top - 60 + vm.$offcanvasInner.scrollTop(),
            left: $(this).offset().left
          })
        })
        .on('mouseleave', '.ui-datepicker-calendar td.busy', function () {
          vm.$busyTooltip.removeClass('active')
        })

      this.$datepicker
        .datepicker('destroy') // Чистим предыдущую инициализацию календаря
        .datepicker({
          showOtherMonths: true,
          selectOtherMonths: true,
          minDate: 0, // Минимальная дата - сегодняшняя
          navigationAsDateFormat: true, // У стрелок смены месяцев добавляются подписи - название месяца
          prevText: 'MM',
          nextText: 'MM',
          beforeShowDay: (date) => {
            // Если в дне все занято, то ячейке в календаре добавляется класс busy
            if (this.busyDays[this.dateFormat(date)] === 'full') {
              return [false, 'busy']
            }

            if (this.dateFormat(date) === this.dateFormat(this.todayDate) && this.isWorkingDayEnded) {
              // Если сегодняшний рабочий день уже закончен
              return [false]
            }
            return [true]
          },
          onSelect: (date) => {
            // Выбор даты в календаре
            this.currentDate = this.$datepicker.datepicker('getDate')

            this.draftBooking.date_temp = this.currentDate.toISOString()

            this.$axios
              .put(`/bookings/${this.draftBooking.id}`, this.draftBooking)
              .then((response) => {
                this.draftBooking = response.data
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('error', error.message)
              })

            this.currentDateText = date

            this.pushEvent({
              event: 'data_widget_' + this.salonNumber
            })

            // Инициализация слайдера выбора дней
            this.timeSliderInit(date)

            // Смена слайда на слайд выбора времени
            this.changeSlide(SLIDE_TIME)
          }
        })
      this.dayNamesMin = this.dayNamesMin || this.$datepicker.datepicker('option', 'dayNamesMin') // Список русских названий дней недели
      this.monthNames = this.monthNames || this.$datepicker.datepicker('option', 'monthNames') // Берем русские названия месяцев из календаря
    },
    addEmptySpaceToSlider () {
      // Добавление пустых ячеек в слайдере, чтобы крайний выбранный день был по центру
      let html = ''
      for (let emptyDay = 0; emptyDay < this.visibleItemsHalf; emptyDay++) {
        html += '<div class="' + this.itemClass + '"></div>'
      }
      return html
    },
    addLeadingZero (number) {
      // Добавление нуля у даты чтобы всегда было 2 цифры
      return number < 10 ? '0' + number : '' + number
    },
    addMonthToSlider (monthDate, isTodayMonth) {
      // Добавление дней месяца в хтмле в слайдер
      let html = '' // Добавляемый в слайдер хтмл
      const daysInMonth = monthDate.getDate()

      for (let day = 0; day < daysInMonth; day++) {
        // Отображать дни:
        if (
          !isTodayMonth || // Если не текущий месяц
          (isTodayMonth && this.todayDate.getDate() < day + 1) || // Или текущий месяц, но не сегодняшний день
          (isTodayMonth && this.todayDate.getDate() === day + 1 && !this.isWorkingDayEnded)
        ) {
          // Или сегодняшний день, но рабочий день еще не закончился
          const itemDate = new Date(monthDate.getFullYear(), monthDate.getMonth(), day + 1)
          const weekday = this.dayNamesMin[itemDate.getDay()]
          const dataTime = itemDate.getTime()
          if (itemDate.getTime() < this.currentDate.getTime()) {
            this.sliderStartPosition++
          }

          html += '<div class="' + this.itemClass + '" data-time="' + dataTime + '">'
          html += '<div class="fitting-widget-time-slider__number">' + (day + 1) + '</div>'
          html += '<div class="fitting-widget-time-slider__weekday">' + weekday + '</div>'
          html += '</div>'
        }
      }

      return html
    },
    timeSliderInit (date) {
      // Инициализация списка часов
      this.scheduleShow(date)
    }
  }
}
</script>

<style lang="scss">
p.privacy-policy-msg {
  display: block;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.3;
  color: black;
  opacity: 1;
}
</style>
