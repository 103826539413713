<template>
  <div class="offcanvas">
    <a class="main-menu-catalog" @click="menuOpen('menu')">Каталог</a>

    <aside class="offcanvas__menu">
      <div class="offcanvas-menu__inner">
        <mobile-menu
          v-if="currentMenu === 'menu'"
          @open="openMenuHandler"
          @close="menuClose"
          @item-clicked="itemClickHandler"
        />

        <div v-if="canShowFilters && currentMenu === 'filters'" class="mobile-filters">
          <div class="mobile-menu-title">
            Фильтр
          </div>
          <Filters @applyed="menuClose" @close="menuClose" />
        </div>

        <fitting-widget
          v-if="currentMenu === 'widget'"
          :source="source"
          @open="openFittingWidgetHandler"
          @close="menuClose"
        />
      </div>
    </aside>

    <div class="offcanvas__shim">
      <a class="offcanvas__close" />
    </div>

    <div class="offcanvas__popup popup-overlay">
      <div class="tooltip">
        <div class="tooltip-inner">
          <div class="mfp-close mfp-close__in" />
          <h2>Запомнить этот сайт</h2>
          <p>Чтобы <strong>создать значок</strong> сайта среди приложений телефона, нажмите на эту иконку</p>
          <i class="icon-favorite" />
          <p>а после выберите <br><strong>Добавить на экран «Домой»</strong></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Bus from '../../assets/js/bus'
import analyticsMixin from '../../assets/mixins/analytics.mixin'
import { CLOSE_OFFCANVAS_EVENT } from '../../assets/js/constants'
import Filters from '../controls/Filters'
import { app } from '../../assets/js/common'
import FittingWidget from './FittingWidget'
import MobileMenu from './MobileMenu'

export default {
  name: 'OffCanvas',
  components: { Filters, MobileMenu, FittingWidget },
  mixins: [analyticsMixin],
  data: () => {
    return {
      currentMenu: null,
      offcanvasOpened: false,
      $body: null,
      $layout: null,
      $offcanvas: null,
      $offcanvasMenu: null,
      $offcanvasInner: null,
      scrollbarWidth: 0,
      // Offcanvas параметры
      menuWidth: 0,
      menuWidthSmall: 0, // Ширина offcanvas на маленьких экранах
      menuDuration: 400, // Скорость анимация появления
      menuEasing: 'swing',
      mobileMenuBreakpoint: 480, // Точка при которой ширина offcanvas меняется на маленькую
      rangeHideDuration: 400,
      backBtnDuration: 400,
      backBtnActive: false,
      source: null
    }
  },
  computed: {
    canShowFilters () {
      return (app.isIpadDesktop || app.tablet || app.mobile) && this.$route.name === 'category-brand'
    }
  },
  mounted () {
    this.$body = $('body')
    this.$layout = $('.layout')
    this.$offcanvas = $('.offcanvas')
    this.$offcanvasMenu = $('.offcanvas__menu')
    this.$offcanvasInner = $('.offcanvas-menu__inner')

    // Offcanvas параметры
    this.offcanvasOpened = false // Открыт ли offcanvas
    this.menuWidth = 400 + this.scrollbarWidth
    this.menuWidthSmall = 270 + this.scrollbarWidth // Ширина offcanvas на маленьких экранах
    this.menuDuration = 400 // Скорость анимация появления
    this.menuEasing = 'swing'
    this.mobileMenuBreakpoint = 480 // Точка при которой ширина offcanvas меняется на маленькую
    this.rangeHideDuration = 400

    const vm = this

    if (document) {
      $(document).on('click', '.main-menu-filter', () => {
        this.menuOpen('filters')
      })

      $(document).on('click', '.fitting-widget-button, .fitting-widget-link', () => {
        this.menuOpen('widget')
      })

      $(document)
        .on('click', '.mobile-menu .nav_item_link', function (event) {
          const $item = $(this).closest('.nav_item')

          if ($item.hasClass('is-dropdown')) {
            event.preventDefault()
            vm.$offcanvas.addClass('level2-opened')
            $item.addClass('active')
            $('.nav_items_wrapper').height($('.nav_item.active .nav_dropdown').height())
            $('.offcanvas__menu').animate({ scrollTop: 0 }, 100)
          }
        })
        .on('click', '.mobile-menu .nav_dropdown_addition_title', function () {
          const dataMenu = $(this).attr('data-menu')
          const $currentCol = $(`.nav_dropdown_col[data-menu-ref="${dataMenu}"]`)

          $currentCol.addClass('active')
          const listHeight = $(`.nav_dropdown_col[data-menu-ref="${dataMenu}"] .nav_dropdown_list`).height()
          const navDropDown = $currentCol.closest('.nav_dropdown')
          navDropDown.height(listHeight)
          $('.mobile-menu .nav_items_wrapper').height(listHeight)
          setTimeout(function () {
            $('.offcanvas__menu').animate({ scrollTop: 0 }, 100)
          }, vm.backBtnDuration)
          $('.offcanvas').addClass('level3-opened')
          $('.nav_back_btn').text('Назад')
        })
        .on('click', '.mobile-menu .nav_dropdown_title', function () {
          const $currentCol = $(this).closest('.nav_dropdown_col')
          $currentCol.addClass('active')
          const listHeight = $currentCol.find('.nav_dropdown_list').height()
          const navDropDown = $currentCol.closest('.nav_dropdown')
          navDropDown.height(listHeight)
          $('.mobile-menu .nav_items_wrapper').height(listHeight)
          setTimeout(function () {
            $('.offcanvas__menu').animate({ scrollTop: 0 }, 100)
          }, vm.backBtnDuration)
          $('.offcanvas').addClass('level3-opened')
          if (
            $(this)
              .closest('.nav_item')
              .hasClass('nav_item__wedding')
          ) {
            $('.nav_back_btn').text('Назад к свадебным платьям')
          } else if (
            $(this)
              .closest('.nav_item')
              .hasClass('nav_item__evening')
          ) {
            $('.nav_back_btn').text('Назад к вечерним платьям')
          }
        })
        .on('click', '.nav_back_btn', function () {
          if (vm.backBtnActive) {
            return
          }

          vm.backBtnActive = true
          if ($('.level3-opened').length) {
            vm.$offcanvas.removeClass('level3-opened')
            $('.mobile-menu .nav_items_wrapper').height($('.mobile-menu .nav_item.active .nav_dropdown').height())
            setTimeout(() => {
              $('.nav_dropdown_col').removeClass('active')
              $('.nav_back_btn').text('Назад в меню')
              vm.backBtnActive = false
            }, vm.backBtnDuration)
          } else if ($('.level2-opened').length) {
            vm.$offcanvas.removeClass('level2-opened')
            $('.mobile-menu .nav_items_wrapper').height($('.mobile-menu .nav_items').height())
            setTimeout(() => {
              $('.nav_item').removeClass('active')
              vm.backBtnActive = false
            }, vm.backBtnDuration)
          }
        })
    }

    $('.offcanvas__shim').click(() => {
      this.menuClose()
    })

    Bus.$on('open-fitting-widget', (source) => {
      this.source = source
      this.openFittingWidgetHandler()
    })

    if (this.$store.state.bookingFormOpened) {
      this.$nextTick(() => {
        this.menuOpen('widget')
        this.$store.commit('setBookingFormOpened', false)
      })
    }
  },
  methods: {
    openFittingWidgetHandler () {
      this.menuOpen('widget')
      this.pushEvent({ event: 'click_widget' + (this.source ? `-${this.source}` : '') })
    },
    openMenuHandler () {
      this.menuOpen('menu')
    },
    menuOpen (menuType) {
      if (this.offcanvasOpened) {
        return
      }

      this.offcanvasOpened = true

      const menuDirection = menuType === 'widget' ? 'right' : 'left' // Если открывается виджет, а не меню, то анимация идет справа
      const animateObj = {}
      animateObj[menuDirection] = 0

      this.currentMenu = menuType

      this.$body
        .addClass('offcanvas-opened ' + menuType + '-opened' + (menuType === 'widget' ? ' offcanvas-right' : ''))
        .removeClass('offcanvas-closed')

      if (menuType === 'menu') {
        setTimeout(() => {
          $('.nav_items_wrapper').height($('.nav_items_wrapper .nav_items').height())
        }, 0)
      }

      this.$offcanvas.stop().animate(animateObj, this.menuDuration, this.menuEasing, () => {
        this.$body.addClass('offcanvas-opened-end')
      })

      setTimeout(() => {
        app.popups()

        if (menuType === 'widget') {
          Bus.$emit('FittingWidgetOpened')
        }
      }, 1)
    },
    itemClickHandler () {
      this.menuClose()
    },
    menuClose () {
      this.currentMenu = null
      const vm = this

      if (!this.offcanvasOpened) {
        return
      }
      if (this.currentMenu === 'widget') {
        Bus.$emit('FittingWidgetClosed')
      }

      const endPosition = window.innerWidth >= this.mobileMenuBreakpoint ? -this.menuWidth : -this.menuWidthSmall
      const animateObj = this.$body.hasClass('widget-opened') ? { right: endPosition } : { left: endPosition }

      this.$body.removeClass('offcanvas-opened-end').addClass('offcanvas-closed')

      this.$offcanvas.stop().animate(animateObj, this.menuDuration, this.menuEasing, () => {
        this.$body.removeClass('offcanvas-opened offcanvas-right menu-opened widget-opened filters-opened')
        vm.$offcanvas.removeStyle('left')
        vm.$offcanvas.removeStyle('right')

        vm.$offcanvas.removeClass('level2-opened')
        vm.$offcanvas.removeClass('level3-opened')

        $('.nav_item').removeClass('active')

        vm.offcanvasOpened = false
      })

      this.source = null

      Bus.$emit(CLOSE_OFFCANVAS_EVENT)
    }
  }
}
</script>

<style lang="scss">
.offcanvas.offcanvas__opened_right {
  right: 0;
}

.offcanvas.offcanvas__opened_left {
  left: 0;
}
</style>
