<template>
  <div id="popup-request" class="popup popup__texture popup__request mfp-hide">
    <client-only>
      <div v-show="!showThankSection" class="popup_request">
        <div v-if="dressName" class="popup_title">
          Хочу купить <br><span>{{ dressName }}</span>
        </div>
        <div v-else class="popup_title">
          Заказ звонка
        </div>
        <div class="popup_description">
          <p>
            Оставьте свой номер телефона и мы свяжемся с вами с 10:00 до 22:00 (по московскому времени).
          </p>
        </div>
        <form action="#" class="popup_form popup_form__request" data-validate="parsley">
          <fieldset>
            <label for="request-name">Ваше имя</label>
            <input
              id="request-name"
              v-model="userName"
              :class="{
                'parsley-error': $v.userName.$dirty && $v.userName.$invalid
              }"
              name="request-name"
              type="text"
            >
            <div v-if="$v.userName.$dirty && $v.userName.$invalid" class="popup_form_error popup_form_error__name">
              <ul class="parsley-error-list">
                <li class="custom-error-message">
                  !
                </li>
              </ul>
            </div>
          </fieldset>
          <fieldset>
            <label for="request-phone">Мобильный телефон</label>
            <div class="popup_phone">
              <input
                id="request-phone"
                v-model="userPhone"
                v-phone="userPhone"
                placeholder="+7 (___) ___ __ __"
                class="group-field"
                :class="{
                  'parsley-error': $v.userPhone.$dirty && $v.userPhone.$invalid
                }"
                name="request-phone"
                type="text"
              >
            </div>
            <div v-if="$v.userPhone.$dirty && $v.userPhone.$invalid" class="popup_form_error popup_form_error__phone">
              <ul class="parsley-error-list">
                <li class="custom-error-message">
                  !
                </li>
              </ul>
            </div>
          </fieldset>
          <fieldset>
            <YandexSmartCaptcha
              v-if="isPopupOpened"
              class="captcha-container"
              sitekey="ysc1_5DCdNVq9VaZHFe6Q7aj6veTyQOBtqsyEIBMODEPI56a7f441"
              :on-success="captchaOnSuccess"
            />
          </fieldset>
          <fieldset>
            <button @click.prevent="onRequestSubmit">
              Отправить
            </button>
          </fieldset>
          Нажимая на кнопку, вы даёте согласие на обработку персональных данных и соглашаетесь с
          <a href="/privacy" target="_blank">политикой конфиденциальности</a>.
        </form>
      </div>
      <div v-show="showThankSection" class="popup_success">
        Спасибо! Мы уже <br>
        перезваниваем вам
      </div>
    </client-only>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapState } from 'vuex'
import { requiredPhoneValidator } from '@/helpers/validators'
import YandexSmartCaptcha from '@/components/YandexSmartCaptcha.vue'
import analyticsMixin from '../../assets/mixins/analytics.mixin'
import Bus from '../../assets/js/bus'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'CallbackPopup',
  components: { YandexSmartCaptcha },
  mixins: [validationMixin, analyticsMixin],
  validations () {
    return {
      userName: {
        required
      },
      userPhone: {
        requiredWithPhoneMask: requiredPhoneValidator
      },
      captchaToken: {
        required
      }
    }
  },
  data: () => {
    return {
      isPopupOpened: false,
      showThankSection: false,
      userName: '',
      userPhone: '',
      requestSent: false,
      captchaToken: null
    }
  },
  computed: {
    dressName () {
      return this.$store.state.buyDress ? `${this.dressPrefix} ${this.$store.state.buyDress.name}` : ''
    },
    dressPrefix () {
      return this.$store.state.buyDressPrefix
    },
    ...mapState({
      requestCallAnalyticType: state => state.requestCallAnalyticType
    })
  },
  mounted () {
    Bus.$on('jsPopupClosed', this.closeHandler)
    Bus.$on('jsPopupOpened', this.openHandler)
  },
  methods: {
    openHandler (item) {
      this.isPopupOpened = item === 'callback'
    },
    captchaOnSuccess (token) {
      this.captchaToken = token
    },
    async onRequestSubmit () {
      if (this.requestSent) {
        return
      }

      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.requestSent = true
        const jsonData = {
          name: this.userName,
          phone: this.userPhone,
          token: this.captchaToken
        }
        if (this.dressName) {
          jsonData.dressName = this.dressName
        }

        try {
          await this.$axios.post('/callbacks', jsonData)
          this.pushEvent({
            event: 'callback'
          })
          this.pushEvent({
            event: this.requestCallAnalyticType
          })
          this.showThankSection = true
          this.requestSent = false
        } catch (error) {
          this.$sentry.captureException(error)
          console.error(error.message)
          // error handler
        }
      }
    },
    closeHandler () {
      this.isPopupOpened = false
      this.showThankSection = false
      this.$store.commit('setBuyDress', null)
      this.$store.commit('setBuyDressPrefix', null)
      this.userName = ''
      this.userPhone = ''
      this.captchaToken = null
      this.$v.$reset()
    }
  }
}
</script>

<style>
.captcha-container {
  margin: 8px auto 0;
  width: 375px;
}

</style>
