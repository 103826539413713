<template>
  <div id="popup-buy-request" class="popup popup__texture popup__request__buy mfp-hide">
    <client-only>
      <div v-show="!showThankSection" class="popup_request">
        <div class="popup_title">
          Корзина
        </div>
        <div class="popup_description">
          <table>
            <tr>
              <td class="col-name">
                Название
              </td>
              <td>{{ dressName }}</td>
            </tr>
            <tr>
              <td class="col-name">
                Размер
              </td>
              <td>
                <span
                  v-for="(size, i) in defaultSizes"
                  :key="i"
                  class="size-chip unselectable"
                  :class="{ choozen: dressSize === size }"
                  @click="dressSize = size"
                >{{ size }}</span>
              </td>
            </tr>
            <tr>
              <td class="col-name">
                Цвет
              </td>
              <td>
                <select v-model="dressColor" class="dress-color-input">
                  <option disabled value="">
                    Выберите цвет
                  </option>
                  <option v-for="color in dressColors" :key="color.code">
                    {{ color.name }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="col-name">
                Количество
              </td>
              <td>
                <span class="chip unselectable" @click="dressLess">−</span>
                <span class="dress-count unselectable">{{ dressCount }}</span>
                <span class="chip unselectable" @click="dressMore">+</span>
              </td>
            </tr>
            <tr>
              <td class="col-name">
                Цена
              </td>
              <td>{{ dressPrice | currency }}<i class="fa fa-rub" aria-hidden="true" /></td>
            </tr>
            <tr>
              <td class="col-name">
                Итого
              </td>
              <td>{{ dressPrice * dressCount | currency }}<i class="fa fa-rub" aria-hidden="true" /></td>
            </tr>
          </table>
        </div>

        <form action="#" class="popup_form popup_form__request" data-validate="parsley">
          <fieldset>
            <label for="request-name">Имя</label>
            <input
              id="request-name"
              v-model="userName"
              :class="{
                'parsley-error': $v.userName.$dirty && $v.userName.$invalid
              }"
              name="request-name"
              type="text"
            >
            <div v-if="$v.userName.$dirty && $v.userName.$invalid" class="popup_form_error popup_form_error__name">
              <ul class="parsley-error-list">
                <li class="custom-error-message">
                  !
                </li>
              </ul>
            </div>
          </fieldset>
          <fieldset>
            <label for="request-name">Фамилия</label>
            <input
              id="request-lastname"
              v-model="userLastname"
              :class="{
                'parsley-error': $v.userLastname.$dirty && $v.userLastname.$invalid
              }"
              name="request-lastname"
              type="text"
            >
            <div v-if="$v.userLastname.$dirty && $v.userLastname.$invalid" class="popup_form_error popup_form_error__name">
              <ul class="parsley-error-list">
                <li class="custom-error-message">
                  !
                </li>
              </ul>
            </div>
          </fieldset>
          <fieldset>
            <label for="request-phone">Мобильный телефон</label>
            <div class="popup_field">
              <input
                id="request-phone"
                v-model="userPhone"
                v-phone="userPhone"
                placeholder="+7 (___) ___ __ __"
                class="group-field"
                :class="{
                  'parsley-error': $v.userPhone.$dirty && $v.userPhone.$invalid
                }"
                autocomplete="none"
                name="request-phone"
                type="text"
              >
            </div>
            <div v-if="$v.userPhone.$dirty && $v.userPhone.$invalid" class="popup_form_error popup_form_error__phone">
              <ul class="parsley-error-list">
                <li class="custom-error-message">
                  !
                </li>
              </ul>
            </div>
          </fieldset>
          <fieldset>
            <label for="request-phone">Адрес</label>
            <div class="popup_field">
              <input
                id="request-address"
                v-model="userAddress"
                class="group-field"
                :class="{
                  'parsley-error': $v.userAddress.$dirty && $v.userAddress.$invalid
                }"
                name="request-address"
                type="text"
              >
            </div>
            <div v-if="$v.userAddress.$dirty && $v.userAddress.$invalid" class="popup_form_error popup_form_error__phone">
              <ul class="parsley-error-list">
                <li class="custom-error-message">
                  !
                </li>
              </ul>
            </div>
          </fieldset>

          <fieldset>
            <label for="request-comment">Комментарий</label>
            <div class="popup_field">
              <textarea
                id="request-comment"
                v-model="comment"
                class="group-field comment-area"
                name="request-comment"
              />
            </div>
          </fieldset>

          <fieldset>
            <YandexSmartCaptcha
              v-if="isPopupOpened"
              class="captcha-container"
              sitekey="ysc1_5DCdNVq9VaZHFe6Q7aj6veTyQOBtqsyEIBMODEPI56a7f441"
              :on-success="captchaOnSuccess"
            />
          </fieldset>

          <fieldset>
            <button @click.prevent="onRequestSubmit">
              Оформить заказ
            </button>
          </fieldset>
          Нажимая на кнопку, вы даёте согласие на обработку персональных данных и соглашаетесь с
          <a href="/privacy" target="_blank">политикой конфиденциальности</a>.
        </form>
      </div>
      <div v-show="showThankSection" class="popup_success">
        Спасибо за оформление заказа,<br> мы свяжемся с вами в ближайшее время.
      </div>
    </client-only>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapState } from 'vuex'
import { requiredPhoneValidator } from '@/helpers/validators'
import YandexSmartCaptcha from '@/components/YandexSmartCaptcha.vue'
import analyticsMixin from '../../assets/mixins/analytics.mixin'
import Bus from '../../assets/js/bus'
const { required } = require('vuelidate/lib/validators')

export default {
  name: 'BuyPopup',
  components: { YandexSmartCaptcha },
  mixins: [validationMixin, analyticsMixin],
  validations () {
    return {
      userName: {
        required
      },
      userLastname: {
        required
      },
      userAddress: {
        required
      },
      userPhone: {
        requiredWithPhoneMask: requiredPhoneValidator
      },
      captchaToken: {
        required
      }
    }
  },
  data: () => {
    return {
      isPopupOpened: false,
      showThankSection: false,
      humanCheckInput: '',
      userName: '',
      userLastname: '',
      userPhone: '',
      userAddress: '',
      dressCount: 1,
      dressSize: 40,
      dressColor: '',
      requestSent: false,
      comment: '',
      captchaToken: null
    }
  },
  computed: {
    dressName () {
      return this.$store.state.buyDress ? `${this.dressPrefix} ${this.$store.state.buyDress.name}` : ''
    },
    dressPrice () {
      return this.$store.state.buyDress?.price || 0
    },
    dressColors () {
      return this.$store.state.buyDress?.colors || []
    },
    dressPrefix () {
      return this.$store.state.buyDressPrefix
    },
    defaultSizes () {
      return this.range(7, 52, 2)
    },
    ...mapState({
      requestCallAnalyticType: state => state.requestCallAnalyticType
    })
  },
  mounted () {
    Bus.$on('jsPopupClosed', this.closeHandler)
    Bus.$on('jsPopupOpened', this.openHandler)
  },
  methods: {
    dressLess () {
      this.dressCount--
      if (this.dressCount <= 0) {
        this.dressCount = 1
      }
    },
    dressMore () {
      this.dressCount++
    },
    range (a, b, step) {
      const c = []
      let temp = 0
      while (a--) {
        c[a] = b - temp
        temp += step
      }
      return c
    },
    openHandler (item) {
      this.isPopupOpened = item === 'buy'
    },
    captchaOnSuccess (token) {
      this.captchaToken = token
    },
    async onRequestSubmit () {
      if (this.requestSent) {
        return
      }

      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.requestSent = true
        const jsonData = {
          name: this.userName,
          lastname: this.userLastname,
          phone: this.userPhone,
          address: this.userAddress,
          count: this.dressCount,
          color: this.dressColor,
          price: this.dressPrice,
          dressName: this.dressName,
          size: this.dressSize,
          comment: this.comment,
          token: this.captchaToken
        }

        try {
          await this.$axios.post('/callbacks', jsonData)
          this.pushEvent({
            event: 'callback'
          })
          this.pushEvent({
            event: this.requestCallAnalyticType
          })
          this.showThankSection = true
          this.requestSent = false
        } catch (error) {
          this.$sentry.captureException(error)
          // error handler
        }
      }
    },
    closeHandler () {
      this.isPopupOpened = false
      this.showThankSection = false
      this.$store.commit('setBuyDress', null)
      this.$store.commit('setBuyDressPrefix', null)
      this.userName = ''
      this.userPhone = ''
      this.userLastname = ''
      this.userAddress = ''
      this.dressCount = 1
      this.dressSize = 32
      this.dressColor = ''
      this.comment = ''
      this.captchaToken = null
      this.$v.$reset()
    }
  }
}
</script>

<style lang="scss">
.captcha-container {
  margin: 8px auto 0;
  width: 375px;
}

.popup_description {
  display: flex;
  justify-content: center;

  table {
    font-size: 16px;
    tr {
      height: 35px;
    }

    td {
      vertical-align: middle;
      &.col-name {
        padding-right: 10px;
        text-align: right;
        font-weight: bold;
      }
    }

    .dress-count-input, .dress-color-input, .dress-size-input {
      height: 25px;
    }
  }
}

.comment-area {
  height: 200px;
  width: 375px;
  padding: 10px;
  font: bold 24px 'ProxyB', Arial, Helvetica, sans-serif;
  color: #0c0c0c;
}

.chip {
  border-radius: 2px;
  font-size: 16px;
  background-color: #dddddd;
  color: black;
  cursor: pointer;
  padding: 6px 8px;
  line-height: 14px;

}

.unselectable {
  user-select: none;
}

.dress-count {
  display: inline-block;
  min-width: 15px;
  text-align: center;
}

.size-chip {
  @extend .chip;
  margin-right: 4px;
  padding-left: 6px;
  padding-right: 6px;

  &.choozen {
    background-color: #fa809d;
    color: white;
  }
}
</style>
