<template>
  <div
      ref="container"
  />
</template>

<script>
const YANDEX_SMART_CAPTCHA_SCRIPT_LINK = "https://smartcaptcha.yandexcloud.net/captcha.js"

export default {
  name: 'YandexSmartCaptcha',
  props: {
    sitekey: {
      type: String,
      required: true
    },
    callback: Function,
    hl: {
      type: String,
      default: 'ru'
    },
    test: Boolean,
    webview: Boolean,
    invisible: {
      type: Boolean,
      default: false
    },
    shieldPosition: String,
    hideShield: Boolean,
    loadWidget: {
      type: Boolean,
      default: true
    },
    timeout: {
      type: Number,
      default: 2000
    },
    onChallengeHidden: Function,
    onChallengeVisible: Function,
    onNetworkError: Function,
    onSuccess: Function,
    onTokenExpired: Function
  },
  data() {
    return {
      container: null,
      totalAttempts: 10,
    }
  },
  computed: {
    widgetNeedsToBeLoaded() {
      return this.loadWidget
    }
  },
  mounted() {
    this.container = this.$refs.container

    if (this.widgetNeedsToBeLoaded) {
      this.loadWidgetScript()
    }

    this.initWidget()
  },
  beforeDestroy() {
    if (this.widgetNeedsToBeLoaded) {
      document.querySelector(`script[src="${YANDEX_SMART_CAPTCHA_SCRIPT_LINK}?render=onload"]`)?.remove()
    }
  },
  methods: {
    loadWidgetScript() {
      const smartCaptchaScript = document.createElement('script')
      smartCaptchaScript.setAttribute('src', `${YANDEX_SMART_CAPTCHA_SCRIPT_LINK}?render=onload`)
      smartCaptchaScript.setAttribute('defer', 'true')
      document.head.appendChild(smartCaptchaScript)
    },
    initWidget() {
      let attempt = 0
      const isSmartCaptchaLoaded = setInterval(() => {
        if (++attempt === this.totalAttempts) {
          console.warn(
              `Captcha cannot be initialized for ${this.timeout}ms. Make sure widget script is loaded`
          )
          clearInterval(isSmartCaptchaLoaded)
          return
        }

        if (window.smartCaptcha !== undefined) {
          clearInterval(isSmartCaptchaLoaded)

          const widgetId = window.smartCaptcha.render(this.container, {
            sitekey: this.sitekey,
            callback: this.callback,
            hl: this.hl,
            test: this.test,
            webview: this.webview,
            invisible: this.invisible,
            shieldPosition: this.shieldPosition,
            hideShield: this.hideShield,
          })

          this.$emit('widgetInitialized', widgetId)
          this.subscribe(widgetId)
        }
      }, this.timeout / this.totalAttempts)
    },
    subscribe(widgetId) {
      if (this.onChallengeHidden) {
        window.smartCaptcha.subscribe(widgetId, 'challenge-hidden', this.onChallengeHidden)
      }

      if (this.onChallengeVisible) {
        window.smartCaptcha.subscribe(widgetId, 'challenge-visible', this.onChallengeVisible)
      }

      if (this.onNetworkError) {
        window.smartCaptcha.subscribe(widgetId, 'network-error', this.onNetworkError)
      }

      if (this.onSuccess) {
        window.smartCaptcha.subscribe(widgetId, 'success', this.onSuccess)
      }

      if (this.onTokenExpired) {
        window.smartCaptcha.subscribe(widgetId, 'token-expired', this.onTokenExpired)
      }
    }
  }
}
</script>
